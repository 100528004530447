import { render, staticRenderFns } from "./books.vue?vue&type=template&id=62e8b07d&scoped=true&"
import script from "./books.vue?vue&type=script&lang=js&"
export * from "./books.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62e8b07d",
  null
  
)

export default component.exports